module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"cadisenwatch.com – Cadisen Watch Official Store","short_name":"💯 Cadisen Watch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe1af3aba1a1af153fa96414c4fd7251"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://www.cadisenwatch.com/","site_name":"Cadisen Watch Official Store"},"twitter":{"handle":"@onemorewatches","site":"https://www.cadisenwatch.com","cardType":"summary_large_image"},"facebook":{"appId":"2636999079954760"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-shopify-poulo/gatsby-browser.js'),
      options: {"plugins":[],"activatePWA":true,"dirName":"E:\\learning\\gatsby\\cadisenwatches\\cadisenwatches","navigation":{"menu":[{"type":"Menu","displayName":"MENS","link":"","subMenu":[{"displayName":"Watches","link":"/all-mens-watches"}]}]},"footerNavigation":{"menu":[{"type":"Link","displayName":"SHOP","link":"","subMenu":[{"displayName":"Mens","link":"/all-mens-watches"},{"displayName":"About Us","link":"/about"},{"displayName":"Order Track","link":"/track-order"}]},{"type":"Link","displayName":"HELP","link":"","subMenu":[{"displayName":"Shipping","link":"/shipping"},{"displayName":"Returns","link":"/returns"},{"displayName":"FAQ & Contact","link":"/contact"}]},{"type":"Support","link":null,"displayName":"","subMenu":[{"displayName":"Terms & Conditions","link":"/terms-of-service"},{"displayName":"Privacy Policy","link":"/privacy-policy"}]}]},"social":{"youtube":"@cadisenwatch","instagram":"cadisenwatch","facebook":"","pinterest":"","twitter":"","snapchat":""},"paymentMethods":["visa","mastercard","amex","diners","discover","jcb","paypal"],"shopifyOptions":{"password":"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","storeUrl":"onemorewatch.myshopify.com","downloadImages":false,"shopifyConnections":["collections"],"salesChannel":"cadisen","apiVersion":"2024-04"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.cadisenwatch.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
