/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require('typeface-lato');
require('typeface-crimson-text');

// 全局智能预加载控制器
export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    require('intersection-observer'); // 改为require方式
  }

  if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
    // 当.card元素进入观察区域时（视口外200px即开始观察）
    const preloadObserver = new IntersectionObserver((entries) => {  
      entries.forEach(entry => {
        if (entry.isIntersecting) { // 当元素进入观察阈值时
          // 获取卡片内所有懒加载图片
          const images = entry.target.querySelectorAll('img[loading="lazy"]');
          images.forEach(img => {
            // 提升加载优先级
            img.loading = 'eager';  // 立即加载
            img.setAttribute('fetchpriority', 'high'); // 高优先级
            
            // 添加平滑加载效果 // 添加渐显动画
            img.style.transition = 'opacity 0.3s ease';
            img.style.opacity = '0';
            
            // 图片解码完成后显示
            img.decode().then(() => {
              img.style.opacity = '1';
            }).catch(() => {
              img.style.opacity = '1';
            });
          });
          
          preloadObserver.unobserve(entry.target); // 停止观察已处理的元素
        }
      });
    }, {
      rootMargin: '200px 0px',  // 提前200px触发观察
      threshold: 0.001,         // 1像素进入即触发
      trackVisibility: true,    // 跟踪元素可见性
      delay: 100                // 防抖延迟
    });

    // 自动观察所有产品卡片
    document.querySelectorAll('.card').forEach(card => {
      preloadObserver.observe(card);  // 开始观察每个卡片元素
    });
  }
};